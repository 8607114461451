function prettify (num, flag) {
  var n = num.toString();
  var separator = " ";
  if(flag) {
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + separator);
  } else {
    return n.split(' ').join('');
  }
}
$(document).ready(function() {
if($("#creditForm").length > 0) {
  var creditCost = $('#creditCost').val();

  function inputYear(data) {
    var yearLang, from = data.from;
    if(from == 1) yearLang = ' год';
    if(from == 2) yearLang = ' года';
    if(from == 3) yearLang = ' года';
    if(from == 4) yearLang = ' года';
    if(from == 5) yearLang = ' лет';
    $("#creditPeriodVal").val(from + yearLang);
  }
  function inputSum(data) {
    var from = data.from_pretty,
        firstPayment = creditCost - data.from;
    $("#creditSumVal").val(from + ' ₽');
    $("#creditFirstPayment").val(firstPayment);
    $("#creditFirstPaymentVal").val(prettify(firstPayment, true));
  }
  function calcCredit() {
    var creditCostValue = Number.parseFloat($('#creditSum').val()),
        creditRateValue = Number.parseFloat($('#creditRate').val()) / 100 / 12,
        creditPeriodValue = Number.parseFloat($('#creditPeriod').val()) * 12,
        creditMaxPeriodValue = 5 * 12;
    var creditPaymentValue = creditCostValue * (creditRateValue / ( 1 - Math.pow( ( 1 + creditRateValue ), -creditPeriodValue ) ) );
    creditPaymentValue = Math.round(creditPaymentValue);
    var creditMinPaymentValue = creditCost*0.1 * (creditRateValue / ( 1 - Math.pow( ( 1 + creditRateValue ), -creditMaxPeriodValue ) ) );
    creditMinPaymentValue = Math.round(creditMinPaymentValue);
    $('#creditPayment').val(creditPaymentValue);
    $('#creditPaymentVal').val('от '+prettify(creditPaymentValue, true)+ ' ₽ / мес.');
    $('.creditMinPayment').text('от '+prettify(creditMinPaymentValue, true)+ ' ₽ / мес.').css('visibility', 'visible');
  }
  $("#creditPeriod").ionRangeSlider({
    type: "single",
    min: 1,
    max: 5,
    from: 5,
    to: 5,
    step: 1,
    onStart: function (data) {
      inputYear(data);
    },
    onChange: function (data) {
      inputYear(data);
      calcCredit();
    }
  });
  $("#creditSum").ionRangeSlider({
    type: "single",
    min: creditCost*0.1,
    max: creditCost*0.9,
    from: creditCost*0.5,
    to: creditCost,
    step: 1000,
    onStart: function (data) {
      inputSum(data);
    },
    onChange: function (data) {
      inputSum(data);
      calcCredit();
    }
  });
  calcCredit();
}
});